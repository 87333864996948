code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #021d32;
  color: black;
  text-align: center;
  padding: 0;
  margin: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(1, 31, 57, 0.4)), #00060b;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

body {
  margin: 0;
}

ul,
li {
  list-style: none;
  padding: 0;
}

.loading-div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
}

.loading-p {
  color: white;
}

header {
  color: white;
}

header h1 {
  letter-spacing: 0.1em;
  margin-bottom: 0;
}

header h2 {
  margin-bottom: 0.5em;
  padding: 0 4px;
  font-size: 20px;
}

#alert-banner {
  background-color: #111;
  color: white;
  padding: 4px 10px;
  margin-bottom: 0;
  border-bottom: 2px solid #ddd;
}

#alert-banner b {
  font-weight: 600;
}

#alert-banner a {
  font-weight: 500;
  color: #3ec184;
  text-shadow: 0 0 0.5px black;
}

/* - - -  {   START SEARCH FORM    }  - - -  */

#searchForm {
  display: flex;
  align-items: center;
  justify-content: center;
}

#searchForm input.search-bar {
  width: 250px;
  height: 30px;
  margin: 0.5em 0.5em;
  background-color: white;
  border: none;
  border-bottom: 2px solid #3ec184;
  border-radius: 2px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: black;
}

#searchForm input.search-bar:hover {
  background-color: #ddd;
  box-shadow: 0 0 6px #3ec184;
}

#searchForm input.search-bar:focus {
  background-color: black;
  color: white;
  outline: none;
}

#searchForm input.search-button {
  margin: 10px 0;
  width: 100px;
  height: 35px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #3ec184;
  border-radius: 6px;
  cursor: pointer;
  color: black;
}

#searchForm input.search-button:hover {
  color: #3ec184;
  border: 2px solid #3ec184;
  background-color: #ddd;
  box-shadow: 0 0 6px #3ec184;
}

#searchForm input.search-button:active {
  background-color: black;
  box-shadow: 0 0 18px #3ec184;
  color: white;
}

/* - - -  {   END  SEARCH FORM    }  - - -  */

.song-columns {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

/* - - -  {   START SEARCH RESULTS & TRACKLIST   }  - - -  */

div.search-results,
div.tracklist {
  width: 40%;
  max-width: 550px;
  min-width: 350px;
  background-color: rgba(10, 10, 10, 0.7);
  border: 2px solid beige;
  margin: 0 2em;
  padding: 0;
  list-style: none;
  border-radius: 6px;
  max-height: 670px;
  position: relative;
}

div.search-results ul {
  max-height: 600px;
  overflow-y: scroll;
  scrollbar-color: #001d0f #3ec184;
  scrollbar-width: thin;
}

div.tracklist ul {
  max-height: 500px;
  overflow-y: scroll;
  scrollbar-color: #001d0f #3ec184;
  scrollbar-width: thin;
}

div.search-results ul::-webkit-scrollbar,
div.tracklist ul::-webkit-scrollbar {
  display: none;
}

.song-columns h3 {
  margin: 0 0 0.6em 0;
  padding: 0.7em 0 0em 0;
  font-size: 20px;
  letter-spacing: 0.2em;
  text-align: center;
  color: white;
  font-weight: 900;
}

.song-columns ul {
  list-style: none;
  margin: 0;
}

.tracklist input {
  display: block;
  background-color: #3ec184;
  border: none;
  border-bottom: 2px solid white;
  border-radius: 2px;
  text-align: center;
  margin: 0 auto 1em auto;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.tracklist input:focus {
  background-color: #66d09f;
  border-bottom: 2px solid black;
  color: black;
  outline: none;
}

.tracklist input::-webkit-input-placeholder {
  color: #ddd;
  font-weight: 600;
  font-style: italic;
}

.tracklist #tracklist-button {
  height: auto;
  padding: 10px;
  margin: 0.9em auto;
  color: white;
  background-color: #111;
  cursor: pointer;
  border-radius: 10px;
  font-size: 0.9em;
  font-weight: bold;
  text-align: center;
  border: 2px solid #66d09f;
}

.tracklist #tracklist-button:hover {
  color: #66d09f;
  border: 2px solid #3ec184;
}

.tracklist #tracklist-button:active {
  color: white;
  border: 2px solid #3ec184;
  box-shadow: 0 0 15px #3ec184;
}

/* - - -  {   END SEARCH RESULTS & TRACKLIST   }  - - -  */

/* - - -  {   START TRACK   }  - - -  */

.track {
  padding: 1em;
  margin: 0.5em 0.6em;
  font-size: 14px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  background-color: #3ec184;
  color: black;
  transition: all 0.5s;
  text-align: left;
}

.track:hover {
  background-color: #48f1a2;
  color: black;
}

.title-container {
  line-height: 1em;
}

.button-container {
  display: flex;
  width: 20px;
  margin-left: auto;
  align-items: center;
  justify-content: center;
}

.cover-container img {
  width: 55px;
  height: 55px;
  margin-right: 10px;
  margin-top: 6px;
  border-radius: 4px;
}

.track .title {
  display: block;
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.artist-album-separator {
  font-size: 5px;
  vertical-align: text-bottom;
  padding: 0 4px;
}

.track .track-buttons {
  font-size: 22px;
  font-size: 200;
  margin-left: auto;
  padding: 5px;
  margin-left: 4px;
  display: none;
}

.track .track-buttons:hover {
  color: white;
  cursor: pointer;
}

.search-results .add {
  display: block;
}

.search-results .add::after {
  content: "\f0fe";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.tracklist .remove {
  display: block;
}

.tracklist .remove::after {
  content: "\f146";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.save-success {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  margin: 0 auto;
  padding: 30px 0px;
  background-color: #ddd;
  border-radius: 6px;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0 30px black;
}

.success-message {
  margin: auto;
}

#spotify-logo {
  width: 155px;
  height: 100%;
  margin: 6px auto 10px;
  display: block;
}

.save-success p {
  color: black;
  font-weight: bold;
  line-height: 2em;
  margin: 0px 30px;
}

.save-success button {
  margin-top: 10px;
  padding: 6px 12px;
  border: none;
  border-bottom: 2px solid #3ec184;
  border-radius: 6px;
  color: white;
  background-color: #111;
  font-weight: bold;
  cursor: pointer;
}

.save-success button:hover {
  color: black;
  background-color: #3ec184;
  border-bottom: 2px solid black;
}

/* - - -  {   END TRACK   }  - - -  */

@media only screen and (max-width: 800px) {
  header h1 {
    font-size: 25px;
    margin: 1em 0 0.5em 0;
  }

  header h2 {
    font-size: 20px;
    display: none;
  }

  #searchForm input.search-bar {
    width: 200px;
    height: 25px;
  }

  #searchForm input.search-button {
    height: 35px;
    margin: 0;
  }

  /* - - - { TRACK COLUMNS } - - -  */
  .song-columns {
    display: block;
    margin: 1em auto;
    width: 100%;
  }

  div.search-results,
  div.tracklist {
    width: 80%;
    margin: 1em auto;
    max-height: 420px;
  }

  div.search-results ul::-webkit-scrollbar,
  div.tracklist ul::-webkit-scrollbar {
    display: none;
  }

  div.search-results ul,
  div.tracklist ul {
    height: 250px;
    overflow-y: scroll;
    scrollbar-color: #001d0f #3ec184;
    scrollbar-width: thin;
  }

  .track {
    padding: 0 1em 0 0.6em;
    margin: 0.5em 0.6em;
    font-size: 14px;
    border-bottom: 2px solid black;
    border-radius: 2px;
    display: flex;
    align-items: center;
    background-color: #3ec184;
    transition: all 0.5s;
  }

  .cover-container img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    margin-top: 6px;
    border-radius: 4px;
  }

  .save-success {
    top: 38%;
  }
}

/* { A U T H E N T I C A T I O N   P A G E } */

main.auth {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  color: white;
}

.auth-p {
  margin: 15px 15px;
  font-weight: bold;
  line-height: 1.75em;
}

.auth-button {
  padding: 5px 10px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
}
